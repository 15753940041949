@import 'tools.module.scss';
@import 'bamboohr_overrides.module.scss';
@import 'grommet_overrides.module.scss';

.line-through { text-decoration: line-through !important; }
.preline { white-space: pre-line; }

.disabled { cursor: default !important; }

.visibility-hidden { visibility: hidden; }

.relative { position: relative; }

.absolute {
  position: absolute;
  right: 0;
  left: 0;
}

.image-light {
  -webkit-filter: brightness(0) invert(1);
  filter:  brightness(0) invert(1);
}

// Lazyload BEGIN *
.fade-out.lazyloaded {
  opacity: 1;
}
// Lazyload END *

// Fading Text BEGIN *
.fade-out {
  will-change: opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.75s ease-out;
  -o-transition: opacity 0.75s ease-out;
  transition: opacity 0.75s ease-out;
}
// Fading Text END *
