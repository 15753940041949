.navbarNotFixed, .authNavbar, .accordionItem, .profileAnchor {
  /*! keep */
}

.navbarScroll {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background: rgba(255, 255, 255, 0.95) !important;
}
