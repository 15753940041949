@font-face {
font-family: '__appFont_0fc452';
src: url(/_next/static/media/23fa11f65e79ce93-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__appFont_Fallback_0fc452';src: local("Arial");ascent-override: 95.06%;descent-override: 25.02%;line-gap-override: 0.00%;size-adjust: 99.94%
}.__className_0fc452 {font-family: '__appFont_0fc452', '__appFont_Fallback_0fc452'
}.__variable_0fc452 {--app-font-primary: '__appFont_0fc452', '__appFont_Fallback_0fc452'
}

#scss_BambooHR__rFD1s{font-family:var(--app-font-primary) !important}#scss_BambooHR__rFD1s img{width:auto}.scss_checkbox-drop__rfv82 div::-webkit-scrollbar{width:.6rem}.scss_checkbox-drop__rfv82 div::-webkit-scrollbar-thumb{border-radius:6px;background:#979197}.scss_checkbox-drop__rfv82 label>div{margin-right:.6rem;max-width:15px;max-height:15px}.scss_checkbox-drop__rfv82 input{background-color:rgba(232,238,243,.75);border-radius:20px;border:none;font-size:.75rem}.scss_checkbox-drop__rfv82 button{border-top:.2px solid #c4c4c4;background-color:#fff}.scss_checkbox-drop__rfv82 button span{font-size:.75rem}@media(hover: hover)and (pointer: fine),only screen and (-ms-high-contrast: active),(-ms-high-contrast: none){.scss_checkbox-drop__rfv82 button:hover label span{color:#788af7}.scss_checkbox-drop__rfv82 button:hover label div>div{border:1px solid #788af7 !important}}.scss_checkbox-drop__rfv82 button>div{padding:.6rem .75rem}.scss_line-through__ojLTB{text-decoration:line-through !important}.scss_preline__7ROMK{white-space:pre-line}.scss_disabled__Yvg5m{cursor:default !important}.scss_visibility-hidden__ppeoC{visibility:hidden}.scss_relative__rsic5{position:relative}.scss_absolute__TjNkX{position:absolute;right:0;left:0}.scss_image-light__XaYny{filter:brightness(0) invert(1)}.scss_fade-out__cZVGG.scss_lazyloaded__qBias{opacity:1}.scss_fade-out__cZVGG{will-change:opacity;backface-visibility:hidden;opacity:0;transition:opacity .75s ease-out}
.Header_navbarNotFixed__SeP9a,.Header_authNavbar__dNGBu,.Header_accordionItem__4RGFU,.Header_profileAnchor__0bUty{/*! keep */}.Header_navbarScroll___plMN{transition:all .2s ease-in-out;background:rgba(255,255,255,.95) !important}
body, html {
  margin: 0px;
  word-break: break-word;
  font-size: 26px;
  min-width: 280px;
  font-family: var(--app-font-primary) !important;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 159.9375em) {
  body, html { font-size: 24px; }
}

@media only screen and (max-width: 145.9375em) {
  body, html { font-size: 22px; }
}

@media only screen and (max-width: 131.9375em) {
  body, html { font-size: 20px; }
}

@media only screen and (max-width: 117.9375em) {
  body, html { font-size: 18px; }
}

@media only screen and (max-width: 103.9375em) {
  body, html { font-size: 16px; }
}

@media only screen and (max-width: 89.9375em) {
  body, html { font-size: 14px; }
}

@media only screen and (max-width: 82.9375em) {
  body, html { font-size: 13px; }
}

@media only screen and (max-width: 75.9375em) {
  body, html { font-size: 12px; }
}


img {
  vertical-align: middle;
  max-width: 100%;;
  width: 100%;
}

input, textarea { font-family: var(--font-primary) !important; }

input[type=file],
input[type=file]::-webkit-file-upload-button {
  cursor: pointer !important;;
}

input[type=file]:disabled,
input[type=file]:disabled::-webkit-file-upload-button {
  cursor: default !important;;
}

:root {
  --app-highlight: #004FFE;
  --app-secondary-highlight: #174EA6;
  --search-field-color: rgba(242, 243, 254, 0.7);
  --search-selection-color: rgba(120, 138, 247, 0.2);
  --alternate-row-bg: rgba(242, 243, 254, 0.5);
  --dashboard-segment-color: rgba(120, 138, 247, 0.2);
  --dashboard-segment-hover-color: rgba(120, 138, 247, 0.33);
  --dashboard-bg: rgba(242, 243, 254, 0.7);
  --dashboard-bg-darken: rgba(120, 138, 247, 0.33);
  --new-page-bg: rgba(242, 243, 254, 0.7);
  --light-grey: #efefef;
  --alpha-white: rgba(255, 255, 255, 0.7);
  --pagination-color: rgba(120, 138, 247);
  --pagination-hover-color: rgba(120, 138, 247, 0.6);
  --dusty-blue: rgba(84, 141, 183, 0.1);
  --box-shadow: 0px 7px 18px rgba(13, 38, 76, 0.19);
  --box-shadow-hover: 0 14px 30px rgba(13, 38, 76, 0.25);
  --box-shadow-value: 1px 2px 8px;
  --box-shadow-hover-value: 1px 4px 12px 2px;
  --vw100: 100vw;
  --vw96p187: 96.187vw;
  --vw70: 70vw;
  --vw55: 55vw;
  --vw50: 50vw;
  --vw48: 48vw;
  --vw46: 46vw;
  --vw44: 44vw;
  --vw35p45: 35.45vw;
  --vw33p35: 33.35vw;
  --vw31: 31vw;
  --vw30: 30vw;
  --vw27: 27vw;
  --vw25: 25vw;
  --vw16p25: 16.25vw;
  --vw16: 16vw;
  --vw15p25: 15.25vw;
  --vw12: 12vw;
  --vw11p5: 11.5vw;
  --vw11: 11vw;
  --vw10: 10vw;
  --vw9p5: 9.5vw;
  --vw9: 9vw;
  --vw7p725: 7.725vw;
  --vw7p5: 7.5vw;
  --vw5p725: 5.725vw;
  --vw4: 4vw;
  --vw3p25: 3.25vw;
  --vw2p625: 2.625vw;
  --vw2: 2vw;
  --vw1p75: 1.75vw;
  --vw1p5: 1.5vw;
  --vw1p25: 1.25vw;
  --vw1: 1vw;
  --vw0p875: 0.875vw;
}

@media only screen and (min-width: 2560px) {
  :root {
    --vw100: 2560px;
    --vw96p187: 2462.39px;
    --vw70: 1792px;
    --vw55: 1408px;
    --vw50: 1280px;
    --vw48: 1228.8px;
    --vw46: 1177.6px;
    --vw44: 1126.4px;
    --vw35p45: 907.52px;
    --vw33p35: 853.75px;
    --vw31: 793.6px;
    --vw30: 768px;
    --vw27: 691.2px;
    --vw25: 640px;
    --vw16p25: 416px;
    --vw16: 409.6px;
    --vw15p25: 390.4px;
    --vw12: 307.2px;
    --vw11p5: 294.4px;
    --vw11: 281.6px;
    --vw10: 256px;
    --vw9p5: 243.2px;
    --vw9: 230.4px;
    --vw7p725: 197.76px;
    --vw7p5: 192px;
    --vw5p725: 146.56px;
    --vw4: 102.4px;
    --vw3p25: 83.2px;
    --vw2p625: 67.2px;
    --vw2: 51.2px;
    --vw1p75: 44.8px;
    --vw1p5: 38.4px;
    --vw1p25: 32px;
    --vw1: 25.6px;
    --vw0p875: 22.4px;
  }
}

@media only screen and (max-width: 280px) {
  :root {
    --vw100: 280px;
    --vw96p187: 269.324px;
    --vw70: 196px;
    --vw55: 154px;
    --vw50: 140px;
    --vw48: 134.4px;
    --vw44: 123.2px;
    --vw35p45: 99.26px;
    --vw33p35: 93.375px;
    --vw31: 86.8px;
    --vw30: 84px;
    --vw27: 75.6px;
    --vw25: 70px;
    --vw16p25: 45.5px;
    --vw16: 44.8px;
    --vw15p25: 42.7px;
    --vw12: 33.6px;
    --vw11p5: 32.2px;
    --vw11: 30.8px;
    --vw10: 28px;
    --vw9p5: 26.6px;
    --vw9: 25.2px;
    --vw7p725: 21.63px;
    --vw7p5: 21px;
    --vw5p725: 16.03px;
    --vw4: 11.2px;
    --vw3p25: 9.1px;
    --vw2p625: 7.35px;
    --vw2: 5.6px;
    --vw1p75: 4.9px;
    --vw1p5: 4.2px;
    --vw1p25: 3.5px;
    --vw1: 2.8px;
    --vw0p875: 2.45px;
  }
}

